body {
  background: #ffffff;
  background-image: none;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
}
body,
html,
.App,
#root,
.auth-wrapper {
  height: auto;
}
.underline {
  text-decoration: underline;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  background-color: #ffffff;
  color: white;
  z-index: 1;
  padding: 30px 30px 0px 30px;
}
.default-inner {
  background: #ffffff;
  transition: all 0.3s;
  position: relative;
  margin-top: 80px;
}
.contanier-type1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.white-box {
  background-color: white !important;
  border: 1px solid #e5e9f2;
  border-radius: 7px;
  width: 450px !important;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 360px) {
  .white-box {
    padding: 10px !important;
  }
}
.box-body {
  width: 100%;
  display: flex;
  justify-content: center;
}

.maincont {
  margin-left: 70px;
}
main {
  padding: 35px;
}

/* login page */
.login-log {
  text-align: left;
  padding-bottom: 10px;
}
.login-log img {
  max-width: 200px;
}
.auth-login-icon {
  border: 1px solid #00d26e;
  border-radius: 4px;
  margin-top: 10px;
  margin-top: 20px;
  padding: 4px;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.auth-login-icon span {
  font-weight: 700 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #171717;
  font-size: 16px;
}
.googleicon {
  max-width: 100%;
  text-align: center;
}
.googleicon button,
.facebookicon button {
  border: 0px !important;
  box-shadow: none !important;
  background-color: transparent;
  font-size: 14px;
}
.facebook-login-icon img {
  max-width: 20px;
  margin-right: 10px;
}
.facebookicon {
  padding: 8px 0px;
}
.forgot-password {
  right: 30px;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 14px;
  padding-top: 5px;
  color: #6b7280;
  margin: 0;
}
.forgot-password a {
  color: #60c99f;
  text-decoration: none;
}

.form-control {
  border: 1px solid #e5e7eb !important;
  border-radius: 4px !important;
  height: 50px;
  color: #171717 !important;
  font-weight: 500;
  font-size: 16px;
}
.login-checkbox {
  margin-right: 10px;
}
.checkbox-label {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-size: 14px;
  color: #9ca3af;
}
.btn {
  color: #171717 !important;
  /* font-weight: 400 !important; */
  font-size: 16px !important;
}
.btn-primary {
  border-radius: 4px !important;
  background: white !important;
  height: 50px !important;
  color: black !important;
  padding: 8px 16px;
  border-color: #fe9900 !important;
}

.btn-primary:hover {
  border-radius: 4px !important;
  background: #fe9900 !important;
  height: 50px !important;
  color: white !important;
  padding: 8px 16px;
  border-color: #fe9900 !important;
}

.new-create-account {
  text-align: center;
  margin-top: 16px;
}
.new-create-account p,
.new-create-account p a {
  font-size: 16px;
}
.new-create-account a {
  color: #60c99f;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: #9ca3af;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #e5e7eb;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}
.validation-error {
  color: crimson;
}
.separator:not(:empty)::after {
  margin-left: 0.25em;
}
.btn[disabled] {
  background-color: #e9e9e9 !important;
  color: #171717 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-color: #e9e9e9 !important;
}
.cont-s {
  font-size: 12px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}
.custom-control-label {
  font-weight: 400;
  font-size: 16px;
}
.default-inner h3 {
  font-size: 24px;
}
.password-cont {
  position: relative;
}
.show-password {
  position: absolute;
  right: 10px;
  top: 45px;
  color: #999;
}
.lab {
  font-size: 14px !important;
}

.contanier-type2 {
  width: 500px;
  margin: auto;
  padding: 25px;
  padding-bottom: 0px;
}
.center-title {
  text-align: center;
  margin-bottom: 50px;
}
.contanier-type2 h2 {
  font-size: 32px;
  font-weight: 600;
}
.contanier-type2 .sub-title {
  font-size: 14px;
  color: #393939;
}
.contanier-type2 h3 {
  font-size: 20px;
  font-weight: 600;
}
.contanier-type2 p {
  font-size: 14px;
}

.onboard-cont {
  border: 1px #00d26e solid;
  border-radius: 12px;
  padding: 20px 30px;
  margin: 10px auto;
}
.onboard-warring {
  border: 1px #ffbd06 solid;
  background-color: #fffef5;
}
.onboard-success-btn button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
.onboard-success-btn button.explore {
  border: 1px #00d26e solid;
  background-color: #ffffff !important;
  color: #00865d !important;
}
