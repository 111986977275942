::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-corner {
  background: #f1f1f1;
}
.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-4ggly9.Mui-selected,
.css-1hrukl9.Mui-selected {
  background-color: #fe9900 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.filter-item p:hover {
  background-color: #fe9900 !important;
}

.filter-item p {
  height: 40px;
  border-radius: 4px;
}

.MuiInputBase-root
  .MuiOutlinedInput-root
  .MuiInputBase-colorPrimary
  .Mui-focused
  .MuiInputBase-formControl
  .MuiInputBase-adornedEnd
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
.split-headers .css-t89xny-MuiDataGrid-columnHeaderTitle,
.split-headers .css-mh3zap {
  white-space: normal !important;
  overflow: hidden !important;
  text-overflow: initial !important;
  line-height: 1.5 !important;
}
.css-1bn53lx,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 14px !important;
  height: 38px !important;
  width: 100% !important;
  outline: none !important;
  border: none !important;
  overflow: hidden;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
  height: 10px; /* height of the entire scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scrollbar thumb */
  border-radius: 10px; /* roundness of the scrollbar thumb */
  border: 2px solid #f1f1f1; /* creates padding around thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scrollbar thumb when hovered */
}

.large-font {
  font-size: 14px;
}
